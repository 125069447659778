import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value, onChange, onKeyPress, autoFocus }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [cursorPosition, setCursorPosition] = React.useState<number>(autoFocus ? 0 : -1);

  React.useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
      setCursorPosition(0);
    }
  }, [autoFocus]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow only numbers, backspace, delete, and arrow keys
    if (
      !/^\d$/.test(e.key) && 
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
    ) {
      e.preventDefault();
    }
    
    // Call the onKeyPress prop if provided
    onKeyPress?.(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '');
    if (input.length <= 10) {
      onChange(input);
    }
  };

  const handleSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    // If no selection is made (initial click) and input is empty, set cursor to 0
    if (input.selectionStart === input.selectionEnd && !value) {
      setCursorPosition(0);
    } else {
      setCursorPosition(input.selectionStart || -1);
    }
  };

  // Create array of 10 slots
  const slots = Array(10).fill(null);

  return (
    <div className="relative">
      <div className="flex items-center gap-4">
        <span className="text-3xl">+1</span>
        <div className="flex items-center">
          {/* Area code */}
          <div className="flex gap-1">
            {slots.slice(0, 3).map((_, index) => (
              <div 
                key={`slot-${index}`} 
                className={`w-4 h-10 flex items-center justify-center relative ${
                  cursorPosition === index ? 'border-b-2 border-b-black' : ''
                }`}
              >
                <AnimatePresence mode="wait">
                  {value[index] && (
                    <motion.span
                      key={`${index}-${value[index]}`}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ type: "spring", stiffness: 300, damping: 25 }}
                      className="text-3xl"
                    >
                      {value[index]}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
          
          <span className="mx-4 text-3xl">-</span>
          
          {/* First three digits */}
          <div className="flex gap-1">
            {slots.slice(3, 6).map((_, index) => (
              <div 
                key={`slot-${index + 3}`} 
                className={`w-4 h-10 flex items-center justify-center relative ${
                  cursorPosition === index + 3 ? 'border-b-2 border-b-black' : ''
                }`}
              >
                <AnimatePresence mode="wait">
                  {value[index + 3] && (
                    <motion.span
                      key={`${index + 3}-${value[index + 3]}`}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ type: "spring", stiffness: 300, damping: 25 }}
                      className="text-3xl"
                    >
                      {value[index + 3]}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
          
          <span className="mx-4 text-3xl">-</span>
          
          {/* Last four digits */}
          <div className="flex gap-1">
            {slots.slice(6, 10).map((_, index) => (
              <div 
                key={`slot-${index + 6}`} 
                className={`w-4 h-10 flex items-center justify-center relative ${
                  cursorPosition === index + 6 ? 'border-b-2 border-b-black' : ''
                }`}
              >
                <AnimatePresence mode="wait">
                  {value[index + 6] && (
                    <motion.span
                      key={`${index + 6}-${value[index + 6]}`}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ type: "spring", stiffness: 300, damping: 25 }}
                      className="text-3xl"
                    >
                      {value[index + 6]}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      </div>
      <input
        ref={inputRef}
        type="tel"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onSelect={handleSelect}
        className="opacity-0 absolute inset-0 w-full cursor-text"
        maxLength={10}
      />
    </div>
  );
};

export default PhoneInput;
