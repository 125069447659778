import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from './Sheet';
import { closeRecipeSheet } from '../store/globalSlice';
import RecipeFull from './RecipeFull';

export default function RecipeSheet() {
    const dispatch = useDispatch();
    const { isOpen, recipeData, sheetActions, showExternalLink } = useSelector((state) => state.global.recipeSheet);

    if (!recipeData) return null;

    return (
        <Sheet open={isOpen} onOpenChange={() => dispatch(closeRecipeSheet())}>
            <SheetContent side="right" className="w-full max-w-2xl">
                <SheetHeader>
                    <div className="text-xl font-bold line-clamp-1">{recipeData.headline}</div>
                </SheetHeader>
                
                <div className="overflow-y-auto max-h-[calc(100vh)] p-2">
                    <RecipeFull 
                        recipe={recipeData} 
                        renderTitle={false}
                        actions={sheetActions}
                        showExternalLink={showExternalLink}
                    />
                </div>
            </SheetContent>
        </Sheet>
    );
} 