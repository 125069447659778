import React from 'react';
import PhoneLogin from '../auth/PhoneLogin';

const Prototype = () => {
  return (
    <div className="flex flex-col pl-4 justify-center min-h-screen">
      <PhoneLogin />
    </div>
  );
};

export default Prototype;