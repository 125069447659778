import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface CodeInputProps {
  value: string;
  onChange: (value: string) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  length?: number;
  autoFocus?: boolean;
}

const CodeInput: React.FC<CodeInputProps> = ({ 
  value, 
  onChange, 
  onKeyPress,
  length = 6,
  autoFocus 
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [cursorPosition, setCursorPosition] = React.useState<number>(autoFocus ? 0 : -1);

  React.useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
      setCursorPosition(0);
    }
  }, [autoFocus]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^\d$/.test(e.key) && 
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
    ) {
      e.preventDefault();
    }
    onKeyPress?.(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '');
    if (input.length <= length) {
      onChange(input);
    }
  };

  const handleSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (input.selectionStart === input.selectionEnd && !value) {
      setCursorPosition(0);
    } else {
      setCursorPosition(input.selectionStart || -1);
    }
  };

  const slots = Array(length).fill(null);

  return (
    <div className="relative">
      <div className="flex items-center gap-4">
        <div className="flex gap-2">
          {slots.map((_, index) => (
            <div 
              key={`slot-${index}`} 
              className={`w-12 h-16 flex items-center justify-center relative border rounded-lg
                ${cursorPosition === index ? 'border-black' : 'border-gray-300'}`}
            >
              <AnimatePresence mode="wait">
                {value[index] && (
                  <motion.span
                    key={`${index}-${value[index]}`}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ type: "spring", stiffness: 300, damping: 25 }}
                    className="text-3xl"
                  >
                    {value[index]}
                  </motion.span>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
      <input
        ref={inputRef}
        type="tel"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onSelect={handleSelect}
        className="opacity-0 absolute inset-0 w-full cursor-text"
        maxLength={length}
      />
    </div>
  );
};

export default CodeInput; 