import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGeneratedRecipeId, setHasRecipes, setSelectedTab } from './recipeGenerationSlice';
import RecipeGenerationResponse from './RecipeGenerationResponse';
import RecipeGenerationChat from './RecipeGenerationChat';
import RecipeGenerationRecipePreview from './RecipeGenerationRecipePreview';
import { useGetGeneratedRecipeQuery } from '../../store/apiSlice';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/Tabs';
import { useIsMobile } from '../../components/hooks/use-mobile';

const RecipeGeneration = ({ 
  generatedRecipeId = null, 
  onRecipeIdChange,
  isFullPage = false 
}) => {
  const dispatch = useDispatch();
  const hasRecipes = useSelector((state) => state.recipeGeneration.hasRecipes);
  const selectedTab = useSelector((state) => state.recipeGeneration.selectedTab);
  const isMobile = useIsMobile();

  const { data } = useGetGeneratedRecipeQuery(generatedRecipeId, {
    skip: !generatedRecipeId,
  });

  useEffect(() => {
    if (data?.recipes && Object.keys(data.recipes).length > 0) {
      dispatch(setHasRecipes(true));
    } else {
      dispatch(setHasRecipes(false));
    }
  }, [data?.recipes, dispatch]);

  useEffect(() => {
    // Keep Redux in sync with incoming prop
    dispatch(setGeneratedRecipeId(generatedRecipeId));
    return () => {
      if (isFullPage) {
        dispatch(setGeneratedRecipeId(null));
      }
    };
  }, [generatedRecipeId, dispatch, isFullPage]);

  const containerClasses = isFullPage
    ? "flex h-[calc(100vh-48px)] overflow-hidden"
    : "flex flex-1 overflow-hidden min-h-48";

  const ChatContent = () => (
    <div className="flex-1 flex flex-col overflow-hidden h-full">
      <div className="flex-1 min-h-0 overflow-y-auto">
        <div className="flex flex-col items-center w-full h-full">
          <div className={`w-full flex-1 ${isFullPage ? 'px-6 md:px-10' : ''}`}>
            <RecipeGenerationResponse />
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 border-t border-black w-full">
        <RecipeGenerationChat onRecipeIdChange={onRecipeIdChange} />
      </div>
    </div>
  );

  const RecipeContent = () => (
    hasRecipes && (
      <div className="flex-1 overflow-y-auto h-full">
        <RecipeGenerationRecipePreview />
      </div>
    )
  );

  if (isMobile) {
    return (
      <div className={`${containerClasses} flex-col`}>
        <Tabs 
          value={selectedTab} 
          onValueChange={(value) => dispatch(setSelectedTab(value))}
          className="w-full h-full flex flex-col"
        >
          <TabsList variant="header">
            <TabsTrigger value="chat">Chat</TabsTrigger>
            {hasRecipes && <TabsTrigger value="recipe">Recipe</TabsTrigger>}
          </TabsList>
          <TabsContent value="chat" className="flex-1 overflow-hidden">
            <ChatContent />
          </TabsContent>
          {hasRecipes && (
            <TabsContent value="recipe" className="flex-1 overflow-hidden">
              <RecipeContent />
            </TabsContent>
          )}
        </Tabs>
      </div>
    );
  }

  return (
    <div className={containerClasses}>
      {hasRecipes && (
        <div className="w-1/3 border-r border-black flex flex-col min-h-0">
          <RecipeContent />
        </div>
      )}
      <div className={`${hasRecipes ? 'w-2/3' : 'w-full'} flex flex-col overflow-hidden`}>
        <ChatContent />
      </div>
    </div>
  );
};

export default RecipeGeneration; 