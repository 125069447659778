import React from 'react';
import { ExternalLink, Pencil, Search, PlusCircle } from 'lucide-react';
import NutritionBar from './NutritionBar';
import Card from './Card';
import { formatDuration, floatToString, formatIngredient } from '../utils';
import localIcon from '../assets/cooking_icon.png';
import { Button } from './Button';

export default function RecipeFull({ recipe = {}, renderTitle = true, showExternalLink = true, actions = []}) {
    const {
        headline,
        image,
        rating_value,
        rating_count,
        recipe_yield,
        total_time,
        nutrition,
        url,
        favicon,
        domain_description,
        description,
        parsed_ingredients,
        ingredients,
        parsed_instructions,
        generated_recipe_id,
        recipe_type
    } = recipe || {};

    const RecipeMetadata = () => (
        <div className="grid grid-cols-[auto_1fr] gap-x-4 text-sm mb-2">
            {rating_value && rating_count && (
                <>
                    <span className="font-bold">Rating</span>
                    <span>{Number(rating_value).toFixed(1)} ({rating_count})</span>
                </>
            )}
            {recipe_yield && (
                <>
                    <span className="font-bold">Serves</span>
                    <span>{recipe_yield}</span>
                </>
            )}
            {total_time && (
                <>
                    <span className="font-bold">Total Time</span>
                    <span>{formatDuration(total_time)}</span>
                </>
            )}
        </div>
    );

    const ExternalLinkCard = () => {
        // Return null if external links are disabled
        if (!showExternalLink) return null;

        // Handle native recipe type
        if (recipe_type === 'native') {
            if (!generated_recipe_id) return null;
            return (
                <a 
                    href={`/create_recipe/${generated_recipe_id}`}
                    className="block hover:bg-gray-50 transition-colors"
                >
                    <Card className="flex items-center justify-between p-2 mt-2">
                        <div className="flex items-center gap-2">
                            <img 
                                src={localIcon}
                                alt="Local Recipe"
                                className="w-4 h-4"
                            />
                            <span className="text-sm line-clamp-1">Edit Recipe</span>
                        </div>
                        <ExternalLink className="w-4 h-4 text-blue-600" />
                    </Card>
                </a>
            );
        }

        // Handle external recipe (existing logic)
        return url ? (
            <a 
                href={url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="block hover:bg-gray-50 transition-colors"
            >
                <Card className="flex items-center justify-between p-2 mt-2">
                    <div className="flex items-center gap-2">
                        <img 
                            src={favicon || localIcon} 
                            onError={(e) => { e.target.onerror = null; e.target.src = localIcon; }}
                            alt={domain_description || ''}
                            className="w-4 h-4"
                        />
                        <span className="text-sm line-clamp-1">{domain_description || 'View Recipe'}</span>
                    </div>
                    <ExternalLink className="w-4 h-4 text-blue-600" />
                </Card>
            </a>
        ) : null;
    };

    const ActionButtons = () => (
        actions?.length > 0 ? (
            <div className="flex flex-wrap gap-2 mt-4">
                {actions.map((action, index) => (
                    <Button 
                        key={index}
                        className="max-w-xs line-clamp-1"
                        variant="white" 
                        size="sm"
                        onClick={action.onClick}
                    >
                        {action.label}
                    </Button>
                ))}
            </div>
        ) : null
    );

    const Section = ({ title, children }) => (
        <div className="mt-6">
            <div className="text-xl font-semibold mb-2 pb-2 border-b-4 border-green">{title}</div>
            {children}
        </div>
    );

    // Convert ingredients to array if it's an object
    const ingredientsList = parsed_ingredients ? 
        (Array.isArray(parsed_ingredients) 
            ? parsed_ingredients 
            : Object.values(parsed_ingredients).map(ing => ing.parsed_ingredient)
        ) : (ingredients || []);

    return (
        <div className="max-w-3xl mx-auto mb-4">
            {renderTitle && headline && (
                <h1 className="text-2xl font-bold pb-0">{headline}</h1>
            )}

            <div className="flex gap-6 items-stretch justify-center">
                {image && (
                    <div className="w-[128px] shrink-0">
                        <img 
                            src={image} 
                            alt={headline || ''}
                            className="w-full h-full object-cover border border-black"
                        />
                    </div>
                )}
                <div className="flex-1">
                    {(rating_value || rating_count || recipe_yield || total_time) && <RecipeMetadata />}
                    {nutrition && <NutritionBar 
                        protein_calories={nutrition?.protein_calories}
                        fat_calories={nutrition?.fat_calories}
                        carb_calories={nutrition?.carbs_calories}
                    />}
                    <ExternalLinkCard />
                </div>
            </div>
            <ActionButtons />

            {description && (
                <Section title="Overview">
                    <p className="text-sm">{description}</p>
                </Section>
            )}

            {ingredientsList?.length > 0 && (
                <Section title="Ingredients">
                    <ul className="list-disc pl-5 space-y-2">
                        {ingredientsList.map((ingredient, index) => (
                            <li key={index} className="text-sm">
                                {formatIngredient(ingredient)}
                            </li>
                        ))}
                    </ul>
                </Section>
            )}

            {parsed_instructions?.length > 0 && (
                <Section title="Instructions">
                    <ul className="list-disc pl-5 space-y-2">
                        {parsed_instructions.map((instruction, index) => (
                            <li key={index} className="text-sm">{instruction}</li>
                        ))}
                    </ul>
                </Section>
            )}
        </div>
    );
}
