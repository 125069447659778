import { useLocation } from 'react-router-dom';

// Add routes where sidebar should be hidden
const ROUTES_WITHOUT_SIDEBAR = [
  '/signin',
  '/auth/callback',
  // Add other routes as needed
];

export function useShouldShowSidebar() {
  const location = useLocation();
  return !ROUTES_WITHOUT_SIDEBAR.includes(location.pathname);
} 