import React, { useState, useEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import PhoneInput from '../../components/PhoneInput';

const PhoneLoginInput = ({ onSubmit }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = () => {
    if (phoneNumber.length === 10) {
      console.log('Submitting phone number:', phoneNumber);
      onSubmit?.(phoneNumber);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && phoneNumber.length === 10) {
      handleSubmit();
    }
  };

  return (
    <div className="w-full max-w-md space-y-2">
      <p className="text-gray-800">
        Please enter your phone number
      </p>
      
      <div className="flex items-center gap-2">
        <PhoneInput 
          value={phoneNumber} 
          onChange={setPhoneNumber}
          onKeyPress={handleKeyPress}
          autoFocus
        />
        
        {phoneNumber.length === 10 && (
          <button
            onClick={handleSubmit}
            className="p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
            aria-label="Submit phone number"
          >
            <ChevronRight size={24} className="text-black"/>
          </button>
        )}
      </div>
      
      <p className="text-sm text-gray-800">
        We'll send you a code to login
      </p>
    </div>
  );
};

export default PhoneLoginInput;
