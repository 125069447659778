import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../lib/supabase-client';
import { CALLBACK_URL, SITE_URL } from '../constants';

// New async thunk for sign in
export const signInWithGoogle = createAsyncThunk(
  'auth/signInWithGoogle',
  async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: CALLBACK_URL,
        siteDomain: SITE_URL,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    });
    
    if (error) throw error;
  }
);

// New async thunk for sign out
export const signOut = createAsyncThunk(
  'auth/signOut',
  async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  }
);

// New async thunks for phone authentication
export const signInWithPhone = createAsyncThunk(
  'auth/signInWithPhone',
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase.auth.signInWithOtp({
        phone: phoneNumber,
      });
      
      if (error) throw error;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const verifyPhoneOtp = createAsyncThunk(
  'auth/verifyPhoneOtp',
  async ({ phone, token }, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase.auth.verifyOtp({
        phone,
        token,
        type: 'sms'
      });
      
      if (error) throw error;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  session: null,
  user: null,
  redirectPath: '/schedule',
  loading: false,
  error: null,
  verificationPending: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRedirectPath: (state, action) => {
      state.redirectPath = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signOut.fulfilled, (state) => {
        state.session = null;
        state.user = null;
      })
      .addCase(signInWithPhone.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signInWithPhone.fulfilled, (state) => {
        state.loading = false;
        state.verificationPending = true;
      })
      .addCase(signInWithPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyPhoneOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyPhoneOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.verificationPending = false;
        state.session = action.payload.session;
        state.user = action.payload.user;
      })
      .addCase(verifyPhoneOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSession, setUser, setRedirectPath, setLoading, clearError } = authSlice.actions;
export default authSlice.reducer; 