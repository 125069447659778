import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetGeneratedRecipeQuery } from '../../store/apiSlice';
import RecipeGeneration from './RecipeGeneration';
import { PageHeader, PageHeaderTitle } from '../../components/PageLayout';
import { ArrowLeft } from 'lucide-react';
import { Button } from '../../components/Button';

const RecipeGenerationPage = () => {
    const { generated_recipe_id } = useParams();
    const navigate = useNavigate();

    const { data: recipeData } = useGetGeneratedRecipeQuery(generated_recipe_id, {
        skip: !generated_recipe_id,
    });

    // Get the latest recipe version
    const latestRecipeVersion = recipeData?.recipes 
        ? Math.max(...Object.keys(recipeData.recipes).map(Number))
        : null;
    const latestRecipe = latestRecipeVersion !== null 
        ? recipeData?.recipes[latestRecipeVersion]
        : null;

    const handleRecipeIdChange = (newRecipeId) => {
        if (newRecipeId) {
            navigate(`/create_recipe/${newRecipeId}`, { replace: true });
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <PageHeader>
                <div className="flex items-center gap-2">
                    <Button
                        variant="darkLink"
                        size="icon"
                        onClick={() => navigate('/recipes')}
                    >
                        <ArrowLeft className="h-4 w-4" />
                    </Button>
                    <PageHeaderTitle>
                        {latestRecipe?.headline 
                            ? `Edit ${latestRecipe.headline}`
                            : 'Create recipe'
                        }
                    </PageHeaderTitle>
                </div>
            </PageHeader>
            <RecipeGeneration 
                generatedRecipeId={generated_recipe_id}
                onRecipeIdChange={handleRecipeIdChange}
                isFullPage={true}
            />
        </>
    );
};

export default RecipeGenerationPage; 