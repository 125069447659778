import React from 'react';
import { useSelector } from 'react-redux';
import { useGetGeneratedRecipeQuery } from '../../store/apiSlice';
import localIcon from '../../assets/cooking_icon.png';
import { ChevronRight } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { setSelectedVersion } from './recipeGenerationSlice';

const parseMessage = (content) => {
    // Split by recipe tags while keeping the tags
    const parts = content.split(/(<recipe version="\d+">)/);
    return parts.map(part => {
        // Check if this part is a recipe tag
        const match = part.match(/<recipe version="(\d+)">/);
        if (match) {
            return {
                type: 'recipe-version',
                version: match[1]
            };
        }
        // Return regular text
        return {
            type: 'text',
            content: part
        };
    }).filter(part => part.content !== ''); // Remove empty strings
};

const RecipeVersionCard = ({ version, recipeName }) => {
    const dispatch = useDispatch();
    
    return (
        <div 
            className="bg-white hover:bg-gray-50 inline-flex items-center rounded-lg p-2 border border-black w-fit cursor-pointer"
            onClick={() => dispatch(setSelectedVersion(parseInt(version)))}
        >
            <div className="flex flex-col">
                <div className="font-bold text-base">{recipeName}</div>
                <div className="text-xs text-gray-600">Version {parseInt(version) + 1}</div>
            </div>
            <ChevronRight className="ml-2 h-4 w-4 text-gray-400" />
        </div>
    );
};

const RecipeGenerationChatAssistant = ({ message }) => {
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const { data } = useGetGeneratedRecipeQuery(generatedRecipeId, {
        skip: !generatedRecipeId,
    });
    
    const recipes = data?.recipes || {};
    const messageParts = parseMessage(message.content);
    
    return (
        <div className="flex flex-col items-start gap-3">
            <img 
                src={localIcon}
                className="w-6 h-6 flex-shrink-0 mt-1" 
                alt="Recipe Assistant Icon" 
            />
            <div className="flex flex-col flex-grow">
                <div className="w-full flex flex-col gap-y-2 whitespace-pre-wrap">
                    {messageParts.map((part, index) => (
                        part.type === 'recipe-version' ? (
                            <RecipeVersionCard 
                                key={index} 
                                version={part.version} 
                                recipeName={recipes[part.version]?.headline || 'Loading...'}
                            />
                        ) : (
                            <span key={index}>{part.content.trim()}</span>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RecipeGenerationChatAssistant;
