import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';
import CodeInput from '../../components/CodeInput';

const CodeVerificationInput = ({ phoneNumber, onSubmit, isLoading }) => {
  const [code, setCode] = useState('');

  const handleSubmit = () => {
    if (code.length === 6) {
      console.log('Submitting verification code:', code);
      onSubmit?.(code);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && code.length === 6) {
      handleSubmit();
    }
  };

  return (
    <div className="w-full max-w-md space-y-4">
      <p className="text-gray-800">
        Enter the code
      </p>
      
      <div className="flex items-center gap-2">
        <CodeInput 
          value={code} 
          onChange={setCode}
          onKeyPress={handleKeyPress}
          autoFocus
        />
        
        {code.length === 6 && (
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`p-2 rounded-full bg-blue-500 text-white 
              ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'} 
              transition-colors`}
            aria-label="Submit verification code"
          >
            {isLoading ? (
              <span className="animate-spin">⌛</span>
            ) : (
              <ChevronRight size={24} className="text-black"/>
            )}
          </button>
        )}
      </div>
      
      <p className="text-sm text-gray-600">
        Didn't receive the code? <button className="text-blue-500 hover:underline">Resend</button>
      </p>
    </div>
  );
};

export default CodeVerificationInput; 