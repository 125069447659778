import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetMealPlanQuery } from '../../store/apiSlice';
import { setActiveView } from './mealPlanSlice';
import { Tabs, TabsList, TabsTrigger } from '../../components/Tabs';
import MealPlanMealPlanControlPanel from './MealPlanMealPlanControlPanel';
import GroceryListControlPanel from './GroceryListControlPanel';
import { useGroceryListActions } from '../groceryList/hooks/useGroceryListActions';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Button } from '../../components/Button';

const MealPlanControlPanel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeView = useSelector(state => state.mealPlan.activeView);
    return (
        <div className="w-full">
            <div className="flex items-center w-full justify-between">
                {/* Left side - Back button and View selector */}
                <div className="flex items-center gap-2">
                    <Button
                        variant="darkLink"
                        size="icon"
                        onClick={() => navigate('/schedule')}
                    >
                        <ArrowLeft className="h-4 w-4" />
                    </Button>
                    <Tabs value={activeView} onValueChange={(value) => dispatch(setActiveView(value))}>
                        <TabsList variant="default">
                            <TabsTrigger value="mealPlan">Meal Plan</TabsTrigger>
                            <TabsTrigger value="groceryList">Grocery List</TabsTrigger>
                        </TabsList>
                    </Tabs>
                </div>

                {/* Right side - Controls based on active view */}
                <AnimatePresence mode="wait">
                    {activeView === 'mealPlan' && (
                        <motion.div
                            key="mealPlan"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.15, ease: "easeInOut" }}
                        >
                            <MealPlanMealPlanControlPanel />
                        </motion.div>
                    )}
                    {activeView === 'groceryList' && (
                        <motion.div
                            key="groceryList"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.15, ease: "easeInOut" }}
                        >
                            <GroceryListControlPanel />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default MealPlanControlPanel;
