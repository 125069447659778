import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneLoginInput from './PhoneLoginInput';
import CodeVerificationInput from './CodeVerificationInput';
import { signInWithPhone, verifyPhoneOtp } from '../../store/authSlice';

const PhoneLogin = () => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, verificationPending } = useSelector((state) => state.auth);

  const handlePhoneSubmit = async (number) => {
    // Format phone number to E.164 format
    const formattedNumber = `+1${number}`; // Assuming US numbers, adjust as needed
    
    const resultAction = await dispatch(signInWithPhone(formattedNumber));
    if (!resultAction.error) {
      setPhoneNumber(formattedNumber);
      setStep(2);
    }
  };

  const handleCodeSubmit = async (code) => {
    const resultAction = await dispatch(
      verifyPhoneOtp({
        phone: phoneNumber,
        token: code,
      })
    );
    
    if (!resultAction.error) {
      navigate('/schedule'); // Or your desired protected route
    }
  };

  return (
    <div className="w-full max-w-md">
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}
      
      {step === 1 ? (
        <PhoneLoginInput 
          onSubmit={handlePhoneSubmit}
          isLoading={loading}
        />
      ) : (
        <CodeVerificationInput 
          phoneNumber={phoneNumber}
          onSubmit={handleCodeSubmit}
          isLoading={loading}
        />
      )}
    </div>
  );
};

export default PhoneLogin; 