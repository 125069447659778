"use client"

import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Calendar, Library, Plus, UserCircle2, ChefHat, PanelLeft, AlignJustify } from 'lucide-react';
import localIcon from '../assets/cooking_icon.png';
import { cn } from '../lib/utils';
import { Button } from './Button';

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarProvider,
  SidebarTrigger,
  useSidebar,
} from './Sidebar';
import { openAddExternalRecipeModal } from '../store/globalSlice';
import { useShouldShowSidebar } from '../hooks/use-sidebar-routes';
import { useLocation } from 'react-router-dom';

function SidebarNav() {
  const dispatch = useDispatch();
  const { toggleSidebar } = useSidebar();
  const shouldShow = useShouldShowSidebar();
  const location = useLocation();

  if (!shouldShow) {
    return null;
  }

  return (
    <div className="flex">
      <div className="fixed h-12 flex items-center z-50 transition-all duration-300 ease-in-out left-1">
        <SidebarTrigger />
      </div>
      <Sidebar 
        collapsible="offcanvas"
        className="transition-all duration-300 ease-in-out shrink-0"
        style={{
          "--sidebar-width": "16rem",
        } as React.CSSProperties}
      >
        
        <SidebarHeader className="border-b border-black h-12 flex items-center">
          <div className="flex items-center justify-between w-full h-full px-2">
            <div className="flex items-center gap-2">
              <img 
                src={localIcon}
                className="w-4 h-4 flex-shrink-0 object-contain"
                alt="Mise En Logo"
              />
              <span className="text-xl font-bold">Mise En</span>
            </div>
            <Button
              variant="link"
              size="icon"
              onClick={toggleSidebar}
              className="h-7 w-7 opacity-0 hover:opacity-100 transition-opacity"
            >
              <PanelLeft className="h-4 w-4 text-black" />
              <span className="sr-only">Toggle Sidebar</span>
            </Button>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton 
                variant="outline" 
                asChild
                isActive={location.pathname === '/schedule'}
              >
                <NavLink to="/schedule">
                  <Calendar className="h-4 w-4" />
                  <span>Schedule</span>
                </NavLink>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton 
                variant="outline" 
                asChild
                isActive={location.pathname === '/recipes'}
              >
                <NavLink to="/recipes">
                  <Library className="h-4 w-4" />
                  <span>Library</span>
                </NavLink>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarContent>

        <SidebarFooter className="border-t border-black mb-0 pb-0">
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton variant="outline" asChild>
                <NavLink to="/build_meal_plan">
                  <Plus className="h-4 w-4" />
                  <span>Create Meal Plan</span>
                </NavLink>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton variant="outline" onClick={() => dispatch(openAddExternalRecipeModal())}>
                <Plus className="h-4 w-4" />
                <span>Add External Recipe</span>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton variant="outline" asChild>
                <NavLink to="/create_recipe">
                  <Plus className="h-4 w-4" />
                  <span>Create AI Recipe</span>
                </NavLink>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton 
                variant="outline" 
                asChild
                isActive={location.pathname === '/profile'}
              >
                <NavLink to="/profile">
                  <UserCircle2 className="h-4 w-4" />
                  <span>Profile</span>
                </NavLink>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
      </Sidebar>
    </div>
  );
}

export default SidebarNav; 