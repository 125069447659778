import React, { useEffect, useContext } from 'react';
import MealPlanMealList from './MealPlanMealList';
import MealPlanChat from './MealPlanChat';
import { useParams } from 'react-router-dom';
import { useGetMealPlanQuery, useListMealsQuery, useGetGroceryListQuery } from '../../store/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setMealPlanId } from './mealPlanSlice';
import MealPlanControlPanel from './MealPlanControlPanel';
import { Sheet, SheetContent } from '../../components/Sheet';
import RecipeLibrarySheetContent from './RecipeLibrarySheetContent';
import { closeRecipeLibrarySheet } from './mealPlanSlice';
import CreateGeneratedRecipeSheetContent from './CreateGeneratedRecipeSheetContent';
import ExternalSearchRecipeSheetContent from './ExternalSearchRecipeSheetContent';
import { 
    closeCreateGeneratedRecipeSheet,
    closeExternalSearchRecipeSheet 
} from './mealPlanSlice';
import MealPlanningPreferencesSheetContent from './MealPlanningPreferencesSheetContent';
import { closeMealPlanningPreferencesSheet } from './mealPlanSlice';
import GroceryList from '../groceryList/GroceryList';
import { useGroceryListActions } from '../groceryList/hooks/useGroceryListActions';
import { motion, AnimatePresence } from 'framer-motion';
import { Toaster } from '../../components/Sonner';
import { PageHeader, PageHeaderTitle, PageMain } from '../../components/PageLayout';

const MealPlan = () => {
    const { meal_plan_id } = useParams();
    const dispatch = useDispatch();
    const { data: mealPlan, error: mealPlanError, isLoading: mealPlanLoading } = useGetMealPlanQuery(meal_plan_id);
    const { data, error: mealsError, isLoading: mealsLoading } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const recipeLibraryOpen = useSelector(state => state.mealPlan.sheets.recipeLibrary.isOpen);
    const createGeneratedRecipeOpen = useSelector(state => state.mealPlan.sheets.createGeneratedRecipe.isOpen);
    const externalSearchRecipeOpen = useSelector(state => state.mealPlan.sheets.externalSearchRecipe.isOpen);
    const mealPlanningPreferencesOpen = useSelector(state => state.mealPlan.sheets.mealPlanningPreferences.isOpen);
    const activeView = useSelector(state => state.mealPlan.activeView);

    const groceryListId = mealPlan?.grocery_list_id;
    const { 
        data: groceryListData,
        error: groceryListError,
        isLoading: groceryListLoading 
    } = useGetGroceryListQuery(groceryListId, {
        skip: !groceryListId // Skip the query if there's no grocery list ID
    });

    const groceryList = useSelector(state => state.groceryList.groceryList);
    const isDedupingList = useSelector(state => state.groceryList.isDedupingList);
    
    const { handleDeduplicate } = useGroceryListActions(groceryListId, groceryList);

    const hasMealIdeas = mealPlan?.meal_ideas && mealPlan.meal_ideas.length > 0;

    useEffect(() => {
        if (meal_plan_id) {
            dispatch(setMealPlanId(meal_plan_id));
        }
    }, [meal_plan_id, dispatch]);

    if (mealPlanLoading || mealsLoading) {
        return <div><span className="loading loading-dots loading-lg"></span></div>;
    }

    if (mealPlanError || mealsError) {
        return <div>Error loading meal plan: {mealPlanError?.message || mealsError?.message}</div>;
    }

    return (
        <div className="flex flex-col h-screen">
            <PageHeader>
                <div className="w-full pr-10">
                    <MealPlanControlPanel />
                </div>
            </PageHeader>

            <PageMain className="flex-grow overflow-hidden flex flex-col">
                {activeView === 'groceryList' ? (
                    <div className="flex-grow overflow-y-auto">
                        <div className="w-full max-w-7xl">
                            <GroceryList 
                                groceryListId={groceryListId} 
                                hideControls={true}
                                onDeduplicate={handleDeduplicate}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex-grow overflow-y-auto">
                            <div className="w-full max-w-7xl">
                                <MealPlanMealList />
                            </div>
                        </div>
                    </>
                )}

            </PageMain>
            <AnimatePresence mode="wait">
                {activeView === 'mealPlan' && hasMealIdeas && (
                    <motion.div 
                        key="mealPlanChat"
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 100, opacity: 0 }}
                        transition={{ 
                            type: "easeInOut",
                            duration: 0.2
                        }}
                        className="flex-shrink-0 border-t border-black rounded-t-base w-full"
                    >
                        <div className="w-full px-6 md:px-10">
                            <MealPlanChat />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <Sheet open={recipeLibraryOpen} onOpenChange={() => dispatch(closeRecipeLibrarySheet())}>
                <SheetContent side="bottom" className="max-h-[80vh] overflow-y-auto">
                    <RecipeLibrarySheetContent />
                </SheetContent>
            </Sheet>

            <Sheet open={createGeneratedRecipeOpen} onOpenChange={() => dispatch(closeCreateGeneratedRecipeSheet())}>
                <SheetContent side="bottom" className="max-h-[80vh] overflow-hidden flex flex-col">
                    <CreateGeneratedRecipeSheetContent />
                </SheetContent>
            </Sheet>

            <Sheet open={externalSearchRecipeOpen} onOpenChange={() => dispatch(closeExternalSearchRecipeSheet())}>
                <SheetContent side="bottom" className="max-h-[80vh] overflow-y-auto">
                    <ExternalSearchRecipeSheetContent />
                </SheetContent>
            </Sheet>

            <Sheet 
                open={mealPlanningPreferencesOpen} 
                onOpenChange={() => dispatch(closeMealPlanningPreferencesSheet())}
            >
                <SheetContent side="bottom" className="max-h-[80vh] overflow-y-auto">
                    <MealPlanningPreferencesSheetContent />
                </SheetContent>
            </Sheet>
            <Toaster className="max-h-[200px]" visibleToasts={15} />
        </div>
    );
};

export default MealPlan;
