import './tailwind.css';
import './globals.css';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { setSession, setUser, setLoading } from './store/authSlice';
import { supabase } from './lib/supabase-client';
import RecipeSheet from './components/RecipeSheet';
import RecipeLibrary from './apps/recipeLibrary/RecipeLibrary';
import SignIn from './apps/auth/SignIn';
import Profile from './apps/profile/Profile';
import Styling from './apps/testApps/Styling';
import Animations from './apps/testApps/Animations';
import MealList from './apps/mealSchedule/MealList';
import WebSocketTest from './components/WebSocketTest';
import Meal from './apps/mealSchedule/Meal';
import GroceryListList from './apps/groceryList/GroceryListList';
import BuildMealPlanStructure from './apps/mealPlan/BuildMealPlanStructure';
import MealPlan from './apps/mealPlan/MealPlan';
import Changelog from './apps/changelog/changelog';
import CookModeProto from './apps/cook_mode/CookModeProto';
import ProtectedRoute from './apps/auth/ProtectedRoute';
import RecipeGenerationPage from './apps/recipeGeneration/RecipeGenerationPage';
import AuthCallback from './apps/auth/AuthCallback';
import GroceryListPage from './apps/groceryList/GroceryListPage';
import Prototype from './apps/testApps/Prototype';
import SidebarNav from './components/SidebarNav';
import { SidebarProvider } from './components/Sidebar';
import { useShouldShowSidebar } from './hooks/use-sidebar-routes';
import { cn } from './lib/utils';
import AddExternalRecipe from './apps/recipeLibrary/AddExternalRecipe';

// Move the AppContent into a separate component
function AppContent() {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const shouldShowSidebar = useShouldShowSidebar();

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        dispatch(setSession(session));
        dispatch(setUser(session.user));
      }
      dispatch(setLoading(false));
      setInitialized(true);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      dispatch(setSession(session));
      dispatch(setUser(session?.user ?? null));
      dispatch(setLoading(false));
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  if (!initialized) {
    return <span className="loading loading-dots loading-lg" />;
  }

  return (
    <SidebarProvider defaultOpen={true}>
      <div className="flex min-h-screen bg-gray-100 w-full overflow-x-hidden">
        {shouldShowSidebar && <SidebarNav />}
        <main className={cn(
          "flex-1 flex flex-col min-h-screen w-full",
          shouldShowSidebar && "md:pl-0"
        )}>
          <div className="flex-1">
            <Routes>
              {/* Public routes */}
              <Route path="/signin" element={<SignIn />} />
              <Route path="/styling" element={<Styling />} />
              <Route path="/animations" element={<Animations />} />
              <Route path="/cook_mode_proto" element={<CookModeProto />} />
              <Route path="/prototype" element={<Prototype />} />

              {/* Protected routes */}
              <Route path="/grocery_list/:groceryListId" element={<ProtectedRoute><GroceryListPage /></ProtectedRoute>} />
              <Route path="/" element={<Navigate to="/schedule" />} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="/recipes" element={<ProtectedRoute><RecipeLibrary /></ProtectedRoute>} />
              <Route path="/grocery_lists" element={<ProtectedRoute><GroceryListList/></ProtectedRoute>} />
              <Route path="/build_meal_plan" element={<ProtectedRoute><BuildMealPlanStructure /></ProtectedRoute>} />
              <Route path="/meal_plan/:meal_plan_id" element={<ProtectedRoute><MealPlan /></ProtectedRoute>} />
              <Route path="/create_recipe" element={<ProtectedRoute><RecipeGenerationPage /></ProtectedRoute>} />
              <Route path="/create_recipe/:generated_recipe_id" element={<ProtectedRoute><RecipeGenerationPage /></ProtectedRoute>} />
              <Route path="/schedule" element={<ProtectedRoute><MealList /></ProtectedRoute>} />
              <Route path="/meal/:meal_id" element={<ProtectedRoute><Meal /></ProtectedRoute>} />
              <Route path="/websocket_test" element={<ProtectedRoute><WebSocketTest /></ProtectedRoute>} />
              <Route path="/changelog" element={<ProtectedRoute><Changelog /></ProtectedRoute>} />
              <Route path="/auth/callback" element={<AuthCallback />} />
            </Routes>
          </div>
          <RecipeSheet />
          <AddExternalRecipe />
        </main>
      </div>
    </SidebarProvider>
  );
}

// Main App component
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;