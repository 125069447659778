import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRedirectPath } from '../../store/authSlice';
import PhoneLogin from './PhoneLogin';

const SignIn = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/schedule';

  useEffect(() => {
    dispatch(setRedirectPath(from));
  }, [from, dispatch]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="w-full max-w-md">
        <PhoneLogin />
      </div>
    </div>
  );
};

export default SignIn;