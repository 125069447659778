import * as React from 'react'
import { Input } from '../../components/Input'
import { useListRecipeLibraryQuery, useSearchRecipesWithParamsQuery } from 'src/store/apiSlice'
import { MealIdeaSuggestions } from './MealIdeaSuggestions'
import debounce from 'lodash/debounce'

export function MealIdeaInput({ className, placeholder, onChange, recipeHandler, buildHandler, searchHandler }) {
  const [inputValue, setInputValue] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [slashText, setSlashText] = React.useState('')
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState('')
  
  // Fetch recipes using RTK Query
  const { data: recipes = [] } = useListRecipeLibraryQuery({})

  // Create debounced function
  const debouncedSetSearchTerm = React.useCallback(
    debounce((term) => {
      setDebouncedSearchTerm(term)
    }, 500),
    []
  )

  // API query with skip if search term is too short
  const { data: searchResults, isFetching: isSearching } = useSearchRecipesWithParamsQuery(
    { searchTerm: debouncedSearchTerm, maxValues: 5 },
    { skip: !debouncedSearchTerm || debouncedSearchTerm.length < 3 }
  )

  // Filter recipes based on slashText and split into queued/non-queued
  const { queuedRecipes, otherRecipes } = React.useMemo(() => {
    const searchTerm = slashText.toLowerCase()
    const filtered = recipes.filter(recipe => 
      recipe.headline.toLowerCase().includes(searchTerm)
    )
    return {
      queuedRecipes: filtered.filter(recipe => recipe.queued).slice(0, 5),
      otherRecipes: filtered.filter(recipe => !recipe.queued).slice(0, 5)
    }
  }, [recipes, slashText])

  // Add this to determine if we should show loading state
  const isEffectivelySearching = React.useMemo(() => {
    return slashText.length >= 3 && (isSearching || slashText !== debouncedSearchTerm)
  }, [slashText, debouncedSearchTerm, isSearching])

  const suggestions = [
    {
      group: 'Build Recipe',
      items: [{ 
        type: 'build',
        data: { label: slashText.length > 0 ? `Create recipe "${slashText}"` : 'Create recipe' },
        id: 'create'
      }]
    },
    {
      group: 'Recipe Library',
      subgroups: [
        {
          group: 'Your Queue',
          items: queuedRecipes.map(recipe => ({
            type: 'recipe',
            data: recipe,
            id: recipe.id
          }))
        },
        {
          group: 'Other Recipes',
          items: otherRecipes.map(recipe => ({
            type: 'recipe',
            data: recipe,
            id: recipe.id
          }))
        }
      ]
    },
    {
      group: 'External Recipes',
      items: slashText.length < 3 
        ? [{
            type: 'custom',
            data: { label: 'Type at least 3 characters to search' },
            id: 'min-chars'
          }]
        : isEffectivelySearching
        ? [{ 
            type: 'loading',
            data: { label: 'Searching...' },
            id: 'loading'
          }]
        : searchResults?.length === 0
        ? [{
            type: 'custom',
            data: { label: `No results found for "${slashText}"` },
            id: 'no-results'
          }]
        : [
            ...(searchResults && slashText === debouncedSearchTerm
              ? searchResults.map(recipe => ({
                  type: 'recipe',
                  data: recipe,
                  id: recipe.recipe_id
                }))
              : []),
            {
              type: 'search',
              data: { label: `Search for more "${slashText}"` },
              id: 'search-more'
            }
          ]
    }
  ]

  // Calculate total items for keyboard navigation
  const totalItems = suggestions.reduce((acc, group) => {
    if (group.subgroups) {
      return acc + group.subgroups.reduce((subAcc, subgroup) => subAcc + subgroup.items.length, 0)
    }
    return acc + (group.items?.length || 0)
  }, 0)

  // Update the helper function to check if item is interactive
  const isInteractiveItem = (item) => {
    return ['recipe', 'build', 'search', 'action'].includes(item.type)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value)
    onChange?.(value)

    const slashIndex = value.lastIndexOf('/')
    if (slashIndex !== -1) {
      const textAfterSlash = value.slice(slashIndex + 1)
      setSlashText(textAfterSlash)
      setIsOpen(true)
      if (textAfterSlash.length >= 3) {
        debouncedSetSearchTerm(textAfterSlash)
      }
    } else {
      setIsOpen(false)
    }
  }

  // Helper function to get item at index
  const getItemAtIndex = (index) => {
    let currentIndex = 0
    for (const group of suggestions) {
      if (group.subgroups) {
        for (const subgroup of group.subgroups) {
          for (const item of subgroup.items) {
            if (currentIndex === index) {
              return item
            }
            currentIndex++
          }
        }
      } else {
        for (const item of group.items) {
          if (currentIndex === index) {
            return item
          }
          currentIndex++
        }
      }
    }
    return null
  }

  const handleKeyDown = (e) => {
    if (!isOpen) return

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        setSelectedIndex((prev) => {
          let newIndex = prev
          do {
            newIndex = newIndex > 0 ? newIndex - 1 : totalItems - 1
          } while (!isInteractiveItemAtIndex(newIndex))
          return newIndex
        })
        break
      case 'ArrowDown':
        e.preventDefault()
        setSelectedIndex((prev) => {
          let newIndex = prev
          do {
            newIndex = newIndex < totalItems - 1 ? newIndex + 1 : 0
          } while (!isInteractiveItemAtIndex(newIndex))
          return newIndex
        })
        break
      case 'Enter':
        e.preventDefault()
        const selectedItem = getItemAtIndex(selectedIndex)
        if (!selectedItem) return

        const lastSlashIndex = inputValue.lastIndexOf('/')
        if (lastSlashIndex === -1) return

        const newValue = inputValue.substring(0, lastSlashIndex)

        if (selectedItem.type === 'recipe' && recipeHandler) {
          recipeHandler(selectedItem.data, slashText)
          setInputValue(newValue)
        } else if (selectedItem.type === 'build' && buildHandler) {
          buildHandler(selectedItem.data, slashText)
          setInputValue(newValue)
        } else if (selectedItem.type === 'search' && searchHandler) {
          searchHandler(selectedItem.data, slashText)
          setInputValue(newValue)
        }
        setIsOpen(false)
        break
    }
  }

  // Helper function to determine if an item at a given index is interactive
  const isInteractiveItemAtIndex = (index) => {
    let currentIndex = 0
    for (const group of suggestions) {
      if (group.subgroups) {
        for (const subgroup of group.subgroups) {
          for (const item of subgroup.items) {
            if (currentIndex === index) {
              return isInteractiveItem(item)
            }
            currentIndex++
          }
        }
      } else {
        for (const item of group.items) {
          if (currentIndex === index) {
            return isInteractiveItem(item)
          }
          currentIndex++
        }
      }
    }
    return false
  }

  // Add cleanup
  React.useEffect(() => {
    return () => {
      debouncedSetSearchTerm.cancel()
    }
  }, [debouncedSetSearchTerm])

  return (
    <div className="relative min-w-[384px]">
      <Input
        variant="clean"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className={className}
      />
      
      {isOpen && (
        <div className="absolute left-0 mt-1 inline-block ounded-md border border-gray-200 bg-white shadow-lg divide-y divide-gray-100 z-50">
          {suggestions.map((group) => {
            if (group.subgroups) {
              // Handle Recipe Library with subgroups
              const hasRecipes = group.subgroups.some(subgroup => subgroup.items.length > 0)
              if (!hasRecipes) return null

              return (
                <div key={group.group} className="p-2">
                  <div className="text-xs text-gray-500 mb-1">
                    {group.group}
                  </div>
                  <div>
                    {group.subgroups.map((subgroup) => (
                      subgroup.items.length > 0 && (
                        <div key={subgroup.group} className="mb-2">
                          <div className="text-xs text-gray-400 font-medium mb-1">
                            {subgroup.group}
                          </div>
                          {subgroup.items.map((item, itemIndex) => {
                            // Calculate absolute index for nested items
                            let absoluteIndex = 0
                            for (let i = 0; i < suggestions.indexOf(group); i++) {
                              absoluteIndex += suggestions[i].items?.length || 0
                            }
                            for (let i = 0; i < group.subgroups.indexOf(subgroup); i++) {
                              absoluteIndex += group.subgroups[i].items.length
                            }
                            absoluteIndex += itemIndex

                            return (
                              <div
                                key={item.id}
                                className={selectedIndex === absoluteIndex ? 'bg-gray-100' : ''}
                              >
                                <MealIdeaSuggestions
                                  type={item.type}
                                  data={item.data}
                                  selectedIndex={selectedIndex === absoluteIndex}
                                />
                              </div>
                            )
                          })}
                        </div>
                      )
                    ))}
                  </div>
                </div>
              )
            }

            // Handle non-Recipe Library groups
            return (
              <div key={group.group} className="p-2">
                <div className="text-xs text-gray-500 font-medium mb-1">
                  {group.group}
                </div>
                {group.items.map((item, itemIndex) => {
                  // Calculate the absolute index for this item
                  let absoluteIndex = 0
                  for (let i = 0; i < suggestions.indexOf(group); i++) {
                    if (suggestions[i].subgroups) {
                      absoluteIndex += suggestions[i].subgroups.reduce(
                        (acc, subgroup) => acc + subgroup.items.length, 
                        0
                      )
                    } else {
                      absoluteIndex += suggestions[i].items.length
                    }
                  }
                  absoluteIndex += itemIndex

                  return (
                    <div
                      key={item.id}
                      className={selectedIndex === absoluteIndex ? 'bg-gray-100' : ''}
                    >
                      <MealIdeaSuggestions
                        type={item.type}
                        data={item.data}
                        selectedIndex={selectedIndex === absoluteIndex}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}