import React from 'react';
import RecipeList from './RecipeList';
import { PageHeader, PageHeaderTitle, PageMain } from 'src/components/PageLayout';

function RecipeLibrary() {
    return (
        <>
            <PageHeader>
                <PageHeaderTitle>Library</PageHeaderTitle>
            </PageHeader>
            <PageMain>
                <RecipeList />
            </PageMain>
        </>
    );
}

export default RecipeLibrary;