import { cn } from "src/lib/utils"

export const PageHeaderTitle = ({ className, ...props }) => (
  <span
    className={cn("text-xl font-bold", className)}
    {...props}
  />
)
PageHeaderTitle.displayName = "PageHeaderTitle"

export const PageHeader = ({ className, ...props }) => (
  <div
    className={cn("h-12 px-6 md:px-10 flex items-center border-b border-black", className)}
    {...props}
  />
)
PageHeader.displayName = "PageHeader"

export const PageMain = ({ className, ...props }) => (
  <main
    className={cn("px-6 md:px-10 py-4", className)}
    {...props}
  />
)
PageMain.displayName = "PageMain"
